@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .footer-gradient {
        -webkit-mask: linear-gradient(0deg, white 50%, transparent);
        mask: linear-gradient(0deg, white 50%, transparent);
    }
}


@font-face {
    font-family: "PPFormula-CondensedRegular";
    font-display: block;
    src: url("./assets/fonts/PPFormula-CondensedRegular.woff2") format("woff2");
    font-style: normal;
}
  
@font-face {
    font-family: "PPFormula-CondensedBold";
    font-display: block;
    src: url("./assets/fonts/PPFormula-CondensedBold.woff2") format("woff2");
    font-style: normal;
}

* {
    color: inherit;
    fill: inherit;
}

*::-webkit-scrollbar {
    width: 0px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background: transparent;
}

